document.addEventListener 'DOMContentLoaded', () =>
  # No cookies error
  document.getElementById('no-cookies').classList.remove 'hidden' unless navigator.cookieEnabled

  # Submits on login form
  forgotPasswordForms = document.querySelectorAll('.forgot-password-form')
  forgotPasswordForms.forEach((form)->
    submit = form.querySelector('[type=submit]')

    form.addEventListener 'submit', (e) ->
      e.preventDefault()
      e.stopPropagation()
      submit.classList.add('is-loading')
      messageContainer = form.querySelector('.forgot-password-errors')
      messageContainer.classList.add 'hidden'

      Rails.ajax {
        type: 'POST',
        url: form.getAttribute('action'),
        data: new FormData(form),
        success: (response)->
          submit.classList.remove('is-loading')
          form.innerHTML = JSON.parse(response).html
        error: (response, status, request)->
          message = JSON.parse(response).html
          messageContainer.innerHTML = message
          messageContainer.classList.remove 'hidden'
          submit.classList.remove('is-loading')
      }
  )