// New pack for tailwind-based frontend.
import  "../src/tw-frontpage/index.js";

/*

	Quick Webpacker README

	Webpacker is a ruby gem that makes it easy to integrate Webpack into Rails.
	All files in packs/entrypoints are bundled as outputs, like how the link directive in Sprockets work (see manifest.js)
	The default name for the "packs" folder is javascript. We have changed it to make it more obvious that it's webpacker entry points. (see webpacker.yml)
	Each such entrypoint can be used in views via the javascript_pack_tag or the stylesheet_pack_tag. The can reference the same "pack" and styles and js will be separated in production mode.
	Webpacker takes care of separating css from javascript. In dev mode it doesn't separate it (somehow bundling it in the js), but in production mode it sets extract_css to true. See https://stackoverflow.com/questions/54299581/rails-webpacker-yml-extract-css-option

	Also, see config/webpack/environment.js for where rails-ujs come into play

*/