import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "slide" ]

  initialize() {
    this.index = 0
    this.showCurrentSlide()
  }

  next() {
    this.index++
    if(this.index >= this.slideTargets.length) { this.index = 0 }
    this.showCurrentSlide()
  }

  previous() {
    this.index--
    if(this.index < 0) { this.index = this.slideTargets.length-1 }
    this.showCurrentSlide()
  }

  showCurrentSlide() {
    //console.log(this.index);
    setTimeout(() => {this.next()}, 6000);
    this.slideTargets.forEach((element, index) => {
      element.classList.remove('is-active');
      if(index == this.index) {
        element.classList.add('is-active');
      }
    })
  }
}