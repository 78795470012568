document.addEventListener 'DOMContentLoaded', () =>
  # No cookies error
  document.getElementById('no-cookies').classList.remove 'hidden' unless navigator.cookieEnabled

  # Get all login forms
  loginForms = document.querySelectorAll('.login-form')

  loginForms.forEach (form)=>
    form.addEventListener 'submit', (e) ->

      e.preventDefault()
      e.stopPropagation()

      form_data = new FormData(form)
      messageContainer = form.querySelector('#message')
      messageContainer.parentElement.classList.add 'hidden'

      # The fetch api can be a bit confusing when compared to Mootools Request.JSON that we normally use
      # see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch for details
      fetch form.action, {
        method: 'POST',
        body: form_data
      }
      .then(
        (response) =>
          if(response.ok) # This is really a 302 redirect
            window.location.href = response.url
            window.dataLayer.push
              'event': 'login'
              'userId': response.user_id
              'platform': window.navigator.platform
              'language': window.navigator.language
              'userAgent': window.navigator.userAgent
          else
            # the json() method returns another promise, use .then to get at contents
            # There is also a text() promise that can be used for raw text.
            response.json().then(
              (json) =>
                messageContainer.innerHTML = json.message
                messageContainer.parentElement.classList.remove 'hidden'
                form.querySelector('[type=submit]').classList.remove('is-loading')
            )
      )