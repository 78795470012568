/*
	This file uses javascript 'import' statements. Anything that should be processed through the tailwind/jit pipeline
	should instead be included with the css '@import' statement and placed in the tailwind.css file below.
*/

import "./styles/sprites.scss";
import "./styles/template-preview.scss";
import "./styles/template-sub-page-signup.scss";
import './styles/tailwind.css';

import './javascript/polyfills/Array.find.js';
import './javascript/polyfills/element.matches.js';
import './javascript/polyfills/Object.assign.js';

import parentNodes from './javascript/parent-nodes.coffee';

import './javascript/login-form'
import './javascript/signup-form'
import './javascript/forgot-password'

// Our new modal implementation: OurModal
import OurModal from './javascript/our-modals'
window.OurModal = OurModal; // Make the class globally available

// Stimulus
import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

window.stimulus = Application.start()
const context = require.context("./javascript/stimulus", true, /\.js$/)
stimulus.load(definitionsFromContext(context))
// End stimulus

// This initiates rails-ujs (data-remote on forms etc). We currently only
// use it in app/views/site/pages/fakturamall.haml. However, we also use
// the Rails-object itself for sending ajax requests in some places.
// Search for Rails.ajax in app/packs/src/tw-frontpage to see where.
// This will/can be replaced by turbo as soon as we upgrade to Rails 6
Rails.start();

document.addEventListener('DOMContentLoaded', ()=> {

  let getCookie = (name)=> {
    var value = "; " + document.cookie
    var parts = value.split("; " + name + "=")
    if (parts.length == 2) return parts.pop().split(";").shift()
    return false
  }

  // Switching tabs
  for (const tab of document.querySelectorAll('a[data-tab]')) {
    tab.addEventListener('click', (e)=>
      import('./javascript/tabs').then(module => {
        let swtichTab = module.default
        swtichTab(e)
      })
    )
  }

  document.body.addEventListener('click', function(event) {
    OurModal.handleClick(event);
  })

  // Toggles active state on our "old" data-toggles modals
  document.addEventListener('click', (e)=>
    import('./javascript/data-toggles').then(module => {
      let dataToggles = module.default
        dataToggles(e)
    })
  )

  // Closing modals by clicking outside
  // TODO: This takes the FIRST .modal-close element it finds and triggers a click on that
  // Currently, we use two .modal-close elements to adjust for mobile/desktop, so it can
  // be a bit dodgy unless both .modal-close elements do the same thing.
  // const mouse_click_event = new MouseEvent('click', { view: window, bubbles: false, cancelable: true })
  for (const modal of document.querySelectorAll('.modal')) {
    let modal_background = modal.querySelector(".modal-background")
    if(modal_background) {
      modal_background.addEventListener('click', (e)=> {
        let close_link = modal.querySelector(".modal-close")
        //console.log('Close link found: ' + close_link.classList)
        if(close_link) {
          //console.log('Close link found: ' + close_link.classList)
          if(!modal.classList.contains('handled-by-modal-manager')) {
            close_link.click();
          }
          //close_link.dispatchEvent(mouse_click_event)
        } else {
          console.log("Trying to close modal on outside click, but no .modal-close element found.")
        }
      })
    }
  }

  // This is for our old cookie banner which is currently not used
  // let cookiesNotice = document.querySelector('#cookies-notice')
  // if (cookiesNotice)
  //   cookiesNotice.querySelector('#close-cookies-notice').addEventListener('click', (e)=> {
  //     if (!getCookie('acceptedCookies')) {
  //       e.preventDefault()
  //       e.stopPropagation()
  //       document.cookie = 'acceptedCookies=true;path="/"'
  //       cookiesNotice.classList.remove('slide-in')
  //     }
  //   })
  //   if (!getCookie('acceptedCookies')) {
  //     setTimeout(()=> {
  //       cookiesNotice.classList.add('slide-in')
  //     }, 1500)
  //   }

  // Fetch all .autoclose
  // If they aren't .is-active, add it
  // 6 seconds later, remove .is-active
  let autoclose = (auto_close_element) => {
    // We add the active class on a timeout to trigger the transition from start
    setTimeout(() => auto_close_element.classList.add('is-active'), 0)
    setTimeout(() => auto_close_element.classList.remove('is-active'), 6000)
  }
  document.querySelectorAll('.autoclose').forEach(auto_close_element => {autoclose(auto_close_element)})

})