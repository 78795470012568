import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['linkToVideo', 'heroVideo', 'seekBar', 'volumeBar', 'volumeOn', 'volumeMute', 'playButton', 'pauseButton', 'videoContainer']
  connect() {
  }

  seek() {
    this.heroVideoTarget.currentTime = this.heroVideoTarget.duration * (this.seekBarTarget.value / 100);
  }

  timeBarUpdate() {
    this.seekBarTarget.value = (100 / this.heroVideoTarget.duration) * this.heroVideoTarget.currentTime;
  }

  volumeBarUpdate() {
    this.heroVideoTarget.volume = this.volumeBarTarget.value;
    if (this.volumeBarTarget.value == "0") {
      this.mute();
    } else {
      this.unMute();
    }
  }

  mute() {
    this.heroVideoTarget.muted = true;
    this.volumeOnTarget.style.display = 'none';
    this.volumeMuteTarget.style.display = 'flex';
  }

  unMute() {
    this.heroVideoTarget.muted = false;
    this.volumeOnTarget.style.display = 'flex';
    this.volumeMuteTarget.style.display = 'none';
  }

  volumeOnOrOff() {
    if (this.heroVideoTarget.muted == true) {
      this.unMute();
    } else {
      this.mute();
    }
  }

  play() {
    this.heroVideoTarget.play();
    this.pauseButtonTarget.style.display = 'flex';
    this.playButtonTarget.style.display = 'none';
  }

  pause() {
    this.heroVideoTarget.pause();
    this.pauseButtonTarget.style.display = 'none';
    this.playButtonTarget.style.display = 'flex';
  }

  playOrPause() {
    if (this.heroVideoTarget.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  open() {
    this.linkToVideoTarget.click();
    this.heroVideoTarget.currentTime = 0;
    this.heroVideoTarget.play();
  }

  fullScreen(e) {
    e.preventDefault();
    if (!document.fullscreenElement)
      this.videoContainerTarget.requestFullscreen();
    else
      document.exitFullscreen();
  }
}
