document.addEventListener 'DOMContentLoaded', ()=>
  # Submits on signup form
  signupForms = document.querySelectorAll('.signup-form')

  createHidden = (name, value) ->
    hidden = document.createElement("input")
    hidden.type = "hidden"
    hidden.name = name
    hidden.value = value
    return hidden

  signupForms.forEach((signupForm)->
    signupForm.addEventListener 'submit', (e) ->
      e.stopPropagation()
      e.preventDefault()
      errorsList = signupForm.querySelector('.notification#errors')
      errorsList.classList.add('hidden')
      submitButton = signupForm.querySelector('[type=submit]')
      submitButton.classList.add('is-loading')
      el.classList.remove('error') for el in signupForm.querySelectorAll('input')

      signupForm.querySelector('.screen_width').value = window.screen.width
      signupForm.querySelector('.screen_height').value = window.screen.height

      # If there's a template preview form, add elements from that form to the signup form
      # so we get template name and colors into the signup form
      if template_preview_form = document.getElementById('template_preview_form')
        
        # The invoice template signup form doesn't have name inputs, so we use email instead
        names = signupForm.querySelector("input[type=email]").value.split('@')
        signupForm.appendChild(createHidden("user[firstname]", names[0]))
        signupForm.appendChild(createHidden("user[lastname]", names[1]))

        # Grap entries from template form and append as hiddens to signup form
        template_form_data = new FormData(template_preview_form)
        signup_form_data = new FormData(signupForm)
        for pair in Array.from(template_form_data.entries())
          signupForm.appendChild(createHidden("setting[#{pair[0]}]", pair[1]))

      Rails.ajax {
        type: 'POST',
        url: signupForm.getAttribute('action'),
        data: new FormData(signupForm),
        success: (response)->
          parsed_response = JSON.parse(response)
          submitButton.classList.remove('is-loading')
          # GTM event
          window.dataLayer.push
            'event': 'sign_up'
            'userId': parsed_response.user_id
            'platform': window.navigator.platform
            'language': window.navigator.language
            'userAgent': window.navigator.userAgent

          # Google conversion
          gc = document.createElement('img')
          gc.setAttribute('height', 1)
          gc.setAttribute('width', 1)
          gc.setAttribute('alt', '')
          gc.setAttribute('src', 'https://www.googleadservices.com/pagead/conversion/1064708751/?value=0&label=86BaCJGXQxCP1dj7Aw&guid=ON&script=0')
          gc.setAttribute('style', 'border: none')
          signupForm.appendChild(gc)

          # Facebook conversion
          fbc = document.createElement('img')
          fbc.setAttribute('height', 1)
          fbc.setAttribute('width', 1)
          fbc.setAttribute('alt', '')
          fbc.setAttribute('src', 'https://www.facebook.com/tr?ev=6034634960240&cd[value]=0.00&cd[currency]=SEK&noscript=1')
          fbc.setAttribute('style', 'border: none')
          signupForm.appendChild(fbc)

          window.location.href = parsed_response.location
          return
        error: (response, status, request)->
          parsed_response = JSON.parse(response)
          errors = parsed_response.errors
          alert = parsed_response.alert
          errorsList.innerHTML = ''
          nameErrors = 0
          if errors?
            Object.keys(errors).forEach (error_field) ->
              # The input is used both for adding .error class AND to get the label text
              # that determines what error field name to display to the user
              input = signupForm.querySelector("input[name='user[#{error_field}]']")
              return unless input

              input.classList.add('error')
              label = input.id
              labelText = if label then signupForm.querySelector("label[for=#{label}]").innerHTML else ''
              errors[error_field].forEach (error)->
                errorElement = document.createElement('li')
                errorElement.innerHTML = "#{labelText} #{error}"
                errorsList.appendChild(errorElement)
          if alert?
            errorElement = document.createElement('li')
            errorElement.innerHTML = alert
            errorsList.appendChild(errorElement)
          errorsList.classList.remove 'hidden'
          submitButton.classList.remove('is-loading')
      }
  )
