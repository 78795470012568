import { Controller } from "stimulus"

export default class extends Controller {
  fetch_content(event) {
    if(this.element.dataset.loaded) { return; }

    event.preventDefault()

    // Should this really be a target instead?
    // If we refactor all data-toggles modals to use OurModal instead, we could add this remote modal
    // logic to OurModal instead, and delete this whole controller.
    if(this.element.dataset.opensOurModal) {
      var modal_body_to_fill = document.querySelector(`${this.element.dataset.opensOurModal} .modal-body`);
    } else {
      var modal_body_to_fill = document.querySelector(`${this.element.dataset.toggles} .modal-body`);
    }

    Rails.ajax({
      type: 'GET',
      url: this.element.getAttribute('href'),
      success: (response) => {
        // Since the server responds with a html mime type, the response is already a DOM-element (thx to rails ujs)
        // So we need to use .body.innerHTML to get att the actual HTML.
        modal_body_to_fill.innerHTML = response.body.innerHTML
        this.element.dataset.loaded = 'true'
      },
      error: (response, status, request) => {
        // If we ever want to translate this, there's a key for it already: I18n.load('no_internet_connection')
        // However, I18n isn't loaded in the frontend as of now.
        modal_body_to_fill.innerHTML = "<div class='text-center text-2xl'>Fel: Ingen internetuppkoppling</div>"
      }
    })
  }
}