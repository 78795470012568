//
// New implementation of modals
//
// They have a few key properties:
// * Only one modal is active at a time
// * There is a modal "stack" that keeps track of all opened modals
// * The top most modal in the stack is the current modal
// * The current modal can be easily closed (without knowing it's id)
// * Clicking outside the current modal closes it
// * When the current modal is closed, the next one in the stack is reopened
// * As long as there are open modals, the 'has-open-modal' class is added to document.body
// * When the last modal is closed, it scrolls back to where the first modal was opened
// * Without scrolling, opening a modal would always bring us to the top of the page due to 'has-open-modal' class (which sets position: fixed on mobile)
// * It's impossible to open the same modal/id twice
//
export default class OurModal {

  static allOpen = [];
  static lastScrollY = 0;
  static activeClasses = ['is-active', 'handled-by-modal-manager'];
  // If the window size is less or equal to this, we will scroll back when modal is closed.
  // Currently set to the md breakpoint
  static breakpointForScrollingBack = 768;

  // Opening one modal inactivates all others, they will be reactivated once the modal above them closes
  static open(id, event = null) {
    // Remove the hash at the beginning if we sent it in (makes our new modals have similiar api to data-toggles)
    var id = (id[0] == '#') ? id.substr(1) : id;
    var our_modal = document.getElementById(id)
    if(our_modal && !this.allOpen.includes(our_modal)) {

      // Inactivate all other modals
      this.allOpen.forEach(function(other_modal) { other_modal.classList.remove('is-active'); })

      if(event) { event.preventDefault(); event.stopPropagation(); }
      // When setting the body to fixed via the 'has-open-modal' class
      // we are automatically scrolled to the top, so we store the scroll
      // position before that and return to the same position when closing
      // the modal. Only the modal that activates fixed positioning (on body) stores the position
      if(!document.body.classList.contains('has-open-modal')) { this.lastScrollY = window.scrollY; }

      our_modal.classList.add(...this.activeClasses);
      this.allOpen.push(our_modal);
      document.body.classList.add('has-open-modal');
    }
  }

  static current() {
    return this.allOpen[this.allOpen.length - 1];
  }

  static closeCurrent(event = null) {
    var current_modal = this.allOpen.pop();
    if(current_modal) {
      if(event) { event.preventDefault(); event.stopPropagation(); }

      current_modal.classList.remove(...this.activeClasses);

      // Did we close the last modal?
      if(this.allOpen.length == 0) {
        document.body.classList.remove('has-open-modal');
      } else { // If not, reactivate the next modal in the stack
        this.current().classList.add(...this.activeClasses);
      }
      if(window.innerWidth <= this.breakpointForScrollingBack) {
        //console.log('Scrolling back to ' + this.lastScrollY)
        window.scroll(0, this.lastScrollY);
      }
    }
  }

  //
  // Handles closing of modals by listening to clicks outside of current modal.
  // Also handles clicks on elements with data-opens-our-modal, which is  
  // currently only used in _signup_from_template_form.haml. Modals can otherwise
  // be opened or closed by calling OurModal.open(id, event) and OurModal.closeCurrent(event)
  //
  static handleClick(event) {
    var open_modal_id = event.target.dataset.opensOurModal
    if(open_modal_id) {
      this.open(open_modal_id, event);
    } else {
      // Check if there's an open modal
      if(this.current()) {
        // If the click is inside the current modal, and not on an element inside it with class .modal-background
        // The .modal-background part is so that modals can be "self-contained" and have their own background defined
        // inside them, but a click on the background is still considered an outside click.
        if(event.target.closest('#'+this.current().id) && !event.target.classList.contains('modal-background')) {
          // The click is inside the current modal (and not on .modal-background), so do nothing
        } else {
          // This is currently also handled for elements with .modal class (and our old data-toggles
          // implementation of modals) in index.js
          this.closeCurrent(event);
        }
      }
    }
  }
}