import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    window.onload = (event) => { this.setCorrectScrollHeight() }
  }

  setCorrectScrollHeight() {
    let root = document.querySelector(':root');
    let sumOfChildrensHeight = 0;
    this.element.querySelectorAll('.lovewall-item-container').forEach((elem) => sumOfChildrensHeight += elem.getBoundingClientRect().height)
    //console.log(sumOfChildrensHeight);
    root.style.setProperty('--lovewall-height', sumOfChildrensHeight / 2 + 'px')
  }
}