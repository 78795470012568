import { Controller } from "stimulus"

//
// This is similar to display_document_controller, except that it's only for resizing (making responsive design possible)
//
export default class extends Controller {
	static targets = ['templateIframe'];

	connect() {
		this.resize();
	}

	resize() {
		this.templateIframeTarget.height = this.templateIframeTarget.contentDocument.body.parentNode.getBoundingClientRect().height;
	}
}